import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaLinkedin } from 'react-icons/fa';

const FooterContainer = styled.footer`
  background-color: #1a1a1a;
  color: #fff;
  padding: 3rem 0;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  h3 {
    color: #2196F3;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  
  svg {
    color: #2196F3;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;

    &:hover {
      color: #2196F3;
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #333;
  color: #666;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>About Us</h3>
          <p>
            Medulla Systems is a leading technology company specializing in ERP development,
            AI integration, business intelligence, and mobile app development.
          </p>
        </FooterSection>

        <FooterSection>
          <h3>Contact Information</h3>
          <ContactInfo>
            <ContactItem>
              <FaMapMarkerAlt />
              <span>D-2-2, Block D, SetiaWalk, 47160, Puchong, Selangor, Malaysia</span>
            </ContactItem>
            <ContactItem>
              <FaPhone />
              <span>+60162701108</span>
            </ContactItem>
            <ContactItem>
              <FaEnvelope />
              <span>jhauyong@medulla.com.my</span>
            </ContactItem>
          </ContactInfo>
        </FooterSection>

        <FooterSection>
          <h3>Connect With Us</h3>
          <p>Follow us on social media to stay updated with our latest news and developments.</p>
          <SocialLinks>
            <a href="https://facebook.com/medullasystems" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://linkedin.com/company/medulla-systems" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </SocialLinks>
        </FooterSection>
      </FooterContent>

      <Copyright>
        <p>&copy; {new Date().getFullYear()} Medulla Systems. All rights reserved.</p>
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
