import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import GlobalStyles from './styles/GlobalStyles';

// Import pages (we'll create these next)
const Home = React.lazy(() => import('./pages/Home/Home'));
const Services = React.lazy(() => import('./pages/Services/Services'));
const ServiceDetail = React.lazy(() => import('./pages/Services/ServiceDetail'));
const Products = React.lazy(() => import('./pages/Products/Products'));
const ProductDetail = React.lazy(() => import('./pages/Products/ProductDetail'));
const Contact = React.lazy(() => import('./pages/Contact/Contact'));

function App() {
  return (
    <Router>
      <GlobalStyles />
      <React.Suspense fallback={<div>Loading...</div>}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/:id" element={<ServiceDetail />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </React.Suspense>
    </Router>
  );
}

export default App;
